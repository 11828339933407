import React, {useState} from 'react'
import CompositionView from '../CompositionView/CompositionView'
import AspectRatio from "../../library/AspectRatio"
import {Box, Button, Stack, ToggleButton, ToggleButtonGroup, Typography} from '@mui/material'
import {Link} from 'react-router-dom'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import {Theme} from "../../library/theme/Theme"

interface ThemeDetailViewProps {
  theme: Theme
  handleEditClick: () => void
}

export default function ThemeDetailView({theme, handleEditClick}: ThemeDetailViewProps) {
  const [layoutAspectRatio, setLayoutAspectRatio] = useState<number>(0.75)
  const [startScreenAspectRatio, setStartScreenAspectRatio] = useState<number>(0.75)

  let gfxViewStyle = {
    top: 0,
    left: 0,
  }

  return (
    <>
      <Stack direction="column" spacing={2}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="h1">
            {theme.name}
          </Typography>

          <Button variant="outlined"
                  color="secondary"
                  startIcon={<EditOutlinedIcon/>}
                  onClick={handleEditClick}
                  sx={{
                    maxWidth: 100,
                    mt: 2,
                  }}
          >
            Edit
          </Button>

          <Link to={`demo`}>
            Demo
          </Link>
        </Stack>

        {theme.description &&
            <Typography paragraph>
              {theme.description}
            </Typography>
        }

        {/*<ul className={stylesheet.tags}>*/}
        {/*  {theme.tags.map(tag => {*/}
        {/*    return <li key={tag}>{tag}</li>*/}
        {/*  })}*/}
        {/*</ul>*/}
      </Stack>

      <Stack direction="row"
             spacing={2}
             sx={{mt: 3, maxWidth: "75%"}}
      >
        <Box sx={{flex: 1}}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h2">Start Screen</Typography>
            <ToggleButtonGroup
              color="primary"
              value={startScreenAspectRatio}
              exclusive
              onChange={(event, value) => {
                if (value)
                  setStartScreenAspectRatio(value)
              }}
              size="small"
            >
              {AspectRatio.startScreenRatios.map(ratio => (
                <ToggleButton value={ratio.value}>{ratio.name}</ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Stack>

          <Box sx={{mt: 1}}>
            <CompositionView
              aspectRatio={startScreenAspectRatio}
              graphics={theme.startScreenGraphics}
              style={{
                paddingBottom: (1 / startScreenAspectRatio) * 100 + "%",
                ...gfxViewStyle,
              }}
            />
          </Box>
        </Box>

        <Box sx={{flex: 1}}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h2">Overlay</Typography>
            <ToggleButtonGroup
              color="primary"
              value={layoutAspectRatio}
              exclusive
              onChange={(event, value) => setLayoutAspectRatio(value)}
              size="small"
            >
              {AspectRatio.all.map(ratio => (
                <ToggleButton value={ratio.value}>{ratio.name}</ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Stack>

          <Box sx={{
            mt: 1,
            maxWidth: layoutAspectRatio < 0.5 ? "240px" : "100%",
          }}>
            <CompositionView
              aspectRatio={layoutAspectRatio}
              graphics={theme.layoutGraphics}
              showSampleImage
              style={{
                paddingBottom: (1 / layoutAspectRatio) * 100 + "%",
                ...gfxViewStyle,
              }}
            />
          </Box>
        </Box>
      </Stack>
    </>
  )
}
