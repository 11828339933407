import React from 'react'
import { CSSProperties } from 'react'
import stylesheet from './Spinner.module.scss'

interface SpinnerProps {
  centerVertical?: boolean
  color?: string
  style?: CSSProperties
  className?: string
  thickness?: string
}

export default function Spinner({ centerVertical, color, style, className, thickness }: SpinnerProps) {
  if (!color) color = "#30aade"
  if (!thickness) thickness = "5px"

  let classes = `${stylesheet.spinner} ${className ?? stylesheet.defaultContainer} ${centerVertical ? stylesheet.centerVertical : ""}`

  return (
    <div className={classes} style={style}>
      <div style={{ borderTopColor: color, borderWidth: thickness }}></div>
      <div style={{ borderTopColor: color, borderWidth: thickness }}></div>
      <div style={{ borderTopColor: color, borderWidth: thickness }}></div>
      <div style={{ borderTopColor: color, borderWidth: thickness }}></div>
    </div>
  )
}
