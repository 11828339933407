import React, {Suspense} from 'react'
import './App.css'
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import ExplorerController from './components/ExplorerController/ExplorerController'
import {DndProvider} from 'react-dnd'
import {HTML5Backend} from 'react-dnd-html5-backend'
import Page from './components/Page/Page'
import Demo from './components/Demo/Demo'
import {createTheme, ThemeProvider} from '@mui/material'
import shadows, {Shadows} from '@mui/material/styles/shadows'
import API from "./library/theme/common/API"

function App() {
  const baseUrl = (window as any).social?.config?.api?.baseUrl ?? process.env.REACT_APP_API_BASE_URL as string
  API.configure(baseUrl)

  const theme = createTheme({
    palette: {
      primary: {
        main: '#30aade',
        contrastText: '#ffffff',
      },
      secondary: {
        main: "#333",
        contrastText: "#fff",
      }
    },
    components: {
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 6,
            textTransform: "unset",
            fontSize: "0.9rem"
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            borderRadius: 6,
            textTransform: "unset",
            fontSize: "0.9rem",
            padding: "5px 15px",
          },
        },
      }
    },
    typography: {
      h1: {
        fontSize: "2.2rem",
        fontWeight: 500,
        color: "#2d3341",
      },
      h2: {
        fontSize: "1.5rem",
        fontWeight: 500,
        color: "#2d3341",
      },
      h3: {
        fontSize: "1.3rem",
        fontWeight: 400,
        color: "#2d3341",
      },
      subtitle1: {
        fontSize: "1rem",
        fontWeight: 500,
        color: "#2d3341",
      },
      subtitle2: {
        fontSize: "0.8rem",
        fontWeight: 500,
        color: "#2d3341",
      },
    },
    shadows: shadows.map(() => 'none') as Shadows,
  })

  return (
    <ThemeProvider theme={theme}>
      <DndProvider backend={HTML5Backend}>
        <Suspense fallback={<></>}>
          <BrowserRouter>
            <Routes>
              <Route element={<Page/>}>
                <Route path="/" element={<ExplorerController/>}/>
                <Route path="/themes/:themeId" element={<ExplorerController/>}/>
                <Route path="/themes/:themeId/edit" element={<ExplorerController/>}/>
              </Route>
              <Route path="/themes/:themeId/demo" element={<Demo/>}/>
            </Routes>
          </BrowserRouter>
        </Suspense>
      </DndProvider>
    </ThemeProvider>
  )
}

export default App
