import React, { useRef } from 'react';
import stylesheet from "./GraphicsList.module.scss"
import { useDrag, useDrop } from 'react-dnd';
import { Identifier, XYCoord } from 'dnd-core';
import {ThemeGraphic} from "../../../../library/theme/Theme"

interface GraphicSelectorProps {
  graphic: ThemeGraphic
  isSelected: boolean
  takePosition: (takingPosition: ThemeGraphic, losingPosition: ThemeGraphic) => void 
  handleSelect: (graphic: ThemeGraphic) => void
}

export const sortableThumbnail = 'sortableGraphicThumbnail'

export default function GraphicThumbnail({ graphic, isSelected, takePosition, handleSelect }: GraphicSelectorProps) {
  const ref = useRef<HTMLLIElement>(null)

  const [{ isDragging }, drag] = useDrag(() => ({
    type: sortableThumbnail,
    item: () => {
      return graphic
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    })
  }))

  const [, drop] =
    useDrop<ThemeGraphic,
      void,
      { handlerId: Identifier | null }
    >(
      () => ({
        accept: sortableThumbnail,
        drop: () => {
          handleSelect(graphic)
        },
        hover: (dragGraphic: ThemeGraphic, monitor) => {
          if (!ref.current) return

          const dragIndex = dragGraphic.placement.zIndex
          const hoverIndex = graphic.placement.zIndex

          // Don't try to swap the graphic with itself
          if (dragGraphic.placement.id === graphic.placement.id) return

          // Calculate the dragging position relative to the object below
          const hoverBoundingRect = ref.current?.getBoundingClientRect()
          const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
          const clientOffset = monitor.getClientOffset()
          const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

          // Only perform the move when the mouse has crossed half of the items height
          // When dragging downwards, only move when the cursor is below 50%
          // When dragging upwards, only move when the cursor is above 50%

          // Dragging down - decreasing the Z-index
          if (dragIndex > hoverIndex && hoverClientY < hoverMiddleY) return

          // Dragging up - increasing the Z-index
          if (dragIndex <= hoverIndex && hoverClientY > hoverMiddleY) return

          takePosition(dragGraphic, graphic)
          handleSelect(dragGraphic)
        }
      }),
      [graphic]
    )

  drag(drop(ref))
  return (
    <li
      onClick={() => handleSelect(graphic)}
      ref={ref}
      className={`${isDragging && stylesheet.dragging} ${isSelected && stylesheet.selected}`}
    >
      <img
        className={stylesheet.graphic}
        src={graphic.assets[0].url}
        alt="Graphic"
      />
    </li>
  )
}
