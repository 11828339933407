import {useEffect, useState} from "react"
import ThemeCategory from "./ThemeCategory"
import {normalizeZIndexes} from "./Util"
import {Theme} from "./theme/Theme"
import {fetchPublicThemes} from "./theme/ThemeRequests"

type UseThemes = {
  themes: Theme[] | null;
  categories: ThemeCategory[] | null
  errorCode: number | null
  updateTheme: (updated: Theme) => void;
};

export const useThemes = (): UseThemes => {
  const [themes, setThemes] = useState<Theme[] | null>(null)
  const [categories, setCategories] = useState<ThemeCategory[] | null>(null)
  const [errorCode, setErrorCode] = useState<number | null>(null)

  useEffect(() => {
    fetchPublicThemes((themes) => {
      const normalZIndexThemes = themes.map(t => {
        normalizeZIndexes(t.startScreenGraphics)
        normalizeZIndexes(t.layoutGraphics)
        return t
      })
      setThemes(normalZIndexThemes)
      setCategories(ThemeCategory.getFeaturedCategories(normalZIndexThemes))
    }, setErrorCode)
  }, [])

  const updateTheme = (updated: Theme) => {
    if (!themes) return
    const newThemes = [...themes]
    const index = newThemes.indexOf(updated)
    newThemes[index] = updated
    setThemes(newThemes)
  }

  return {
    themes: themes,
    categories,
    errorCode,
    updateTheme,
  }
}