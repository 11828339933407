import React, {useState} from 'react'
import stylesheet from "./ThemeEditor.module.scss"
import {Box, Button, Stack, ToggleButton, ToggleButtonGroup, Typography} from '@mui/material'
import AspectRatio from '../../../library/AspectRatio'
import PreviewArea from "../PreviewArea/PreviewArea"
import LayerPanel from "../GraphicsEditor/GraphicsList/LayerPanel"
import PlacementEditor from "../GraphicsEditor/PlacementEditor/PlacementEditor"
import {Theme, ThemeGraphic} from "../../../library/theme/Theme"

interface ThemeEditorProps {
  theme: Theme
  handleClose: () => void
}

export default function ThemeEditor({theme, handleClose}: ThemeEditorProps) {
  const [mode, setMode] = useState<"overlay" | "startScreen">("overlay")
  const [editedTheme, setEditedTheme] = useState<Theme>(theme)

  const [currentGraphic, setCurrentGraphic] = useState<ThemeGraphic | undefined>()

  const replaceGraphics = (graphics: ThemeGraphic[]) => {
    const updatedTheme: any = editedTheme.copy()
    updatedTheme[mode] = graphics
    setEditedTheme(updatedTheme)
  }

  let graphics = mode === "overlay" ? theme.layoutGraphics : theme.startScreenGraphics

  const graphicsByZIndex = graphics.sort((g1, g2) => g2.placement.zIndex - g1.placement.zIndex)

  return (
    <Stack direction="row"
           justifyContent="space-evenly"
           spacing={3}
           sx={{
             width: "100%",
             boxSizing: "border-box",
             p: 3,
           }}
    >

      <Stack direction="column" spacing={2} sx={{flex: 1}}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" spacing={2}>
            <Typography variant="h1">{theme.name}</Typography>

            <ToggleButtonGroup color="primary"
                               exclusive

                               value={mode}
                               onChange={(event, value) => {
                                 if (value) setMode(value)
                               }}
            >
              <ToggleButton value="overlay">Overlay</ToggleButton>
              <ToggleButton value="startScreen">Start Screen</ToggleButton>
            </ToggleButtonGroup>
          </Stack>

          <Button variant="contained" onClick={handleClose}>Save & close</Button>
        </Stack>

        <PreviewArea key={mode}
                     graphics={graphicsByZIndex}
                     aspectRatios={mode === "overlay" ? AspectRatio.all : AspectRatio.startScreenRatios}
                     showSampleImage={mode === "overlay"}
        />
      </Stack>

      {currentGraphic &&
          <Box className={stylesheet.panelAppear}
               sx={{
                 height: "100%",
               }}>

            <PlacementEditor placement={currentGraphic.placement}
                             handleChangePlacement={(newPlacement) => {
                               const newGraphics = [...graphicsByZIndex]
                               const index = newGraphics.indexOf(currentGraphic)
                               newGraphics[index].placement = newPlacement
                               replaceGraphics(newGraphics)
                             }
                             }
            />
          </Box>
      }

      <LayerPanel graphics={graphicsByZIndex}
                  selected={currentGraphic}
                  handleSelect={setCurrentGraphic}
                  handleNewOrder={replaceGraphics}
                  sx={{height: "100%"}}
      />

      {/*<Box className={`${stylesheet.framesPanel} ${stylesheet.panelAppear}`}*/}
      {/*     sx={{*/}
      {/*       position: "fixed",*/}
      {/*       bottom: 10,*/}
      {/*       left: 200,*/}
      {/*       right: 260,*/}
      {/*     }}>*/}
      {/*  <Typography variant="subtitle1" gutterBottom>Frames</Typography>*/}
      {/*  <Stack direction="row" spacing={1}>*/}
      {/*    <>*/}
      {/*      {graphicsByZIndex[selectedIndex].assets.map((frame, index) => {*/}
      {/*        return <img key={index} src={frame.url} alt="Animation Frame"/>*/}
      {/*      })}*/}
      {/*      <Button>Add Frame</Button>*/}
      {/*    </>*/}
      {/*  </Stack>*/}
      {/*</Box>*/}
    </Stack>
  )
}
