import {
  Button,
  FormControl,
  InputAdornment,
  InputBaseComponentProps,
  InputLabel,
  OutlinedInput,
  Paper,
  Slider,
  Typography,
  Stack,
} from '@mui/material'
import React, {ChangeEvent, FocusEvent} from 'react'
import AspectRatio from '../../../../library/AspectRatio'
import PositionControl from './PositionControl'
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import {GraphicPlacement} from "../../../../library/theme/graphic/GraphicPlacement"

interface PlacementEditorProps {
  placement: GraphicPlacement
  handleChangePlacement: (placement: GraphicPlacement) => void
}

interface Point {
  x: number,
  y: number
}

export default function PlacementEditor({placement, handleChangePlacement}: PlacementEditorProps) {
  const MAX_SIZE_LIMITS = {
    MIN: 0.01,
    MAX: 10,
  }

  const constrain = (number: number, min: number, max: number) => {
    return Math.max(
      Math.min(number, max),
      min,
    )
  }

  let changePosition = (position: Point, anchor: Point) => {
    let newPlacement = placement.copy()
    newPlacement.position = position
    newPlacement.anchor = anchor
    handleChangePlacement(newPlacement)
  }

  let quickSize = (event: Event, newValue: number | number[]) => {
    const newPlacement = placement.copy()
    newPlacement.maxSize.width = newValue as number / 100
    newPlacement.maxSize.height = newValue as number / 100
    handleChangePlacement(newPlacement)
  }

  const changeRotation = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.currentTarget.value) return

    let newPlacement = placement.copy()
    newPlacement.rotation = parseInt(event.currentTarget.value)
    handleChangePlacement(newPlacement)
  }

  const changeAspectRatio = (event: Event, newValue: number | number[]) => {
    if (!Array.isArray(newValue)) return

    const range = newValue as number[]

    const newPlacement = placement.copy()
    newPlacement.supportedAspectRatio = {
      min: Math.min(...range),
      max: Math.max(...range),
    }
    handleChangePlacement(newPlacement)
  }

  let changeMaxWidth = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.currentTarget.value) return

    let newPlacement = placement.copy()
    let inputValue = Math.round(parseInt(event.currentTarget.value)) / 100
    newPlacement.maxSize.width = constrain(inputValue, MAX_SIZE_LIMITS.MIN, MAX_SIZE_LIMITS.MAX)
    handleChangePlacement(newPlacement)
  }

  let changeMaxHeight = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.currentTarget.value) return

    let newPlacement = placement.copy()
    let inputValue = Math.round(parseInt(event.currentTarget.value)) / 100
    newPlacement.maxSize.height = constrain(inputValue, MAX_SIZE_LIMITS.MIN, MAX_SIZE_LIMITS.MAX)
    handleChangePlacement(newPlacement)
  }

  const selectAllOnFocus = (event: FocusEvent<HTMLInputElement>) => event.target.select()

  const supportedRatios = AspectRatio.all.filter(
    (ratio) => ratio.value <= placement.supportedAspectRatio.max
      && ratio.value >= placement.supportedAspectRatio.min,
  )

  const numericInputProps: InputBaseComponentProps = {
    inputMode: 'numeric', pattern: '[0-9]*',
  }

  const maxSizeProps = {
    endAdornment:
      (
        <InputAdornment position="end">
          %
        </InputAdornment>
      ),
    min: MAX_SIZE_LIMITS.MIN,
    max: MAX_SIZE_LIMITS.MAX,
    step: 1,
    inputProps: {...numericInputProps},
  }

  return (
    <Stack direction="column" justifyContent="flex-start" spacing={2}>
      <Paper variant="outlined" sx={{p: 1.5}}>
        <Typography variant="subtitle1" gutterBottom>Position</Typography>
        <PositionControl
          position={placement.position}
          anchor={placement.anchor}
          handleChange={(position, anchor) => changePosition(position, anchor)}
        />
      </Paper>

      <Paper variant="outlined" sx={{p: 1.5}}>
        <div>
          <Typography variant="subtitle1" gutterBottom>Size</Typography>
          <Slider
            getAriaLabel={() => 'Size'}
            min={0}
            max={200}
            step={5}
            value={Math.round(placement.maxSize.width * 100)}
            onChange={quickSize}
            valueLabelDisplay="auto"
            valueLabelFormat={(value) => `${value}%`}
            getAriaValueText={(value) => `${value}%`}
          />
        </div>

        <div>
          <FormControl sx={{m: 0.5, width: '10ch'}} variant="outlined">
            <InputLabel htmlFor="maxSizeWidth">Max Width</InputLabel>
            <OutlinedInput
              id="maxSizeWidth"
              type="number"
              label="Max Width"
              value={Math.trunc(placement.maxSize.width * 100)}
              onFocus={selectAllOnFocus}
              onChange={changeMaxWidth}
              {...maxSizeProps}
            />
          </FormControl>
          <FormControl sx={{m: 0.5, width: '10ch'}} variant="outlined">
            <InputLabel htmlFor="maxSizeHeight">Max Height</InputLabel>
            <OutlinedInput
              id="maxSizeHeight"
              type="number"
              label="Max Height"
              value={Math.trunc(placement.maxSize.height * 100)}
              onFocus={selectAllOnFocus}
              onChange={changeMaxHeight}
              {...maxSizeProps}
            />
          </FormControl>
        </div>
      </Paper>

      <Paper variant="outlined" sx={{p: 1.5}}>
        <Typography variant="subtitle1" gutterBottom>Rotation</Typography>
        <FormControl sx={{m: 0.5, width: '10ch'}} variant="outlined">
          <OutlinedInput
            id="rotation"
            type="number"
            value={placement.rotation}
            onFocus={selectAllOnFocus}
            onChange={changeRotation}
            endAdornment={
              (
                <InputAdornment position="end">
                  °
                </InputAdornment>
              )
            }
            inputProps={{
              min: -360,
              max: 360,
              step: 5,
              ...numericInputProps,
            }}
          />
        </FormControl>
      </Paper>

      <Paper variant="outlined" sx={{p: 1.5}}>
        <Typography variant="subtitle1" gutterBottom>Frames</Typography>
        <Button variant="outlined" startIcon={<EditOutlinedIcon/>}>Edit</Button>
      </Paper>

      <Button variant="contained"
              color="error"
              sx={{alignSelf: "flex-start"}}
      >
        Delete
      </Button>

      {/* <div>
        <Typography gutterBottom>Supported Aspect Ratios</Typography>
        <Slider
          getAriaLabel={() => 'Supported Aspect Ratios'}
          step={0.1}
          min={0}
          max={2}
          value={[placement.supportedAspectRatio.min, placement.supportedAspectRatio.max]}
          onChange={changeAspectRatio}
          valueLabelDisplay="auto"
          getAriaValueText={(value) => `${value}`}
        />

        {supportedRatios.map((size, index) => {
          return <>
            {size.name}
            {index < supportedRatios.length - 1 && ", "}
          </>
        })}
      </div> */}
    </Stack>
  )
}
