export default class AspectRatio {
  name: string
  value: number

  constructor(name: string, value: number) {
    this.name = name
    this.value = value
  }

  static readonly portrait = new AspectRatio("Portrait", 0.75)
  static readonly landscape = new AspectRatio("Landscape", 1.33333)
  static readonly square = new AspectRatio("Square", 1.0)
  static readonly tall = new AspectRatio("Tall", 0.33333)

  static readonly startScreenRatios = [
    AspectRatio.portrait, 
    AspectRatio.landscape
  ]

  static readonly all = [
    AspectRatio.tall,
    AspectRatio.portrait, 
    AspectRatio.square, 
    AspectRatio.landscape, 
  ]
}
