class SimpleBoothAPI {
  baseUrl: string | null = "https://www.simplebooth.com"

  configure(newBaseUrl: string){
    // Remove any trailing slash
    this.baseUrl = newBaseUrl.replace(/\/$/, '')
  }
}

const API = new SimpleBoothAPI()
export default API