import React, {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import ThemeListCell from './ThemeListCell/ThemeListCell'
import {Box, Chip, Stack, Typography} from "@mui/material"
import {useThemes} from "../../library/UseThemes"
import {Theme} from "../../library/theme/Theme"
import Spinner from "../Spinner/Spinner"

interface ThemeListViewProps {
  handleSelectTheme: (theme: Theme) => void
}

export default function ThemeListView({handleSelectTheme}: ThemeListViewProps) {
  const {themes, categories, errorCode} = useThemes()

  const {themeId} = useParams()

  useEffect(() => {
    if (themes === null)
      return

    if (themeId) {
      const foundTheme = themes.find(theme => theme.id === parseInt(themeId))
      if (foundTheme) {
        handleSelectTheme(foundTheme)
      }
    }
  }, [themes])

  if (errorCode) {
    return <>{errorCode} error</>
  }

  if (!themes) {
    return <>
      <Typography variant="h2">Themes</Typography>
      {!themes &&
          <Spinner style={{marginTop: "20px"}}/>
      }
    </>
  }

  if (categories) {
    return (
      <Box sx={{ height: "100%", width: "275px", overflowY: "auto", boxSizing: "border-box"}}>
        <Typography variant="h2">Themes</Typography>
        <Stack direction="column" spacing={2} sx={{mt: 2}}>
          {categories.map(category => (
            <Box key={category.title}>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="h3">{category.title}</Typography>
                <Chip label={category.themes.length} size="small"/>
              </Stack>
              {category.themes.map(theme => (
                <ThemeListCell
                  key={theme.id}
                  theme={theme}
                  handleSelectTheme={handleSelectTheme}
                />
              ))}
            </Box>
          ))}
        </Stack>
      </Box>
    )
  }

  return <></>
}
