import React, {MouseEvent} from 'react'
import {Button, IconButton, Paper, Stack, SxProps, Tooltip, Typography} from '@mui/material'
import stylesheet from "./GraphicsList.module.scss"
import GraphicThumbnail from './GraphicThumbnail'
import update from 'immutability-helper'
import InfoIcon from '@mui/icons-material/Info'
import {normalizeZIndexes} from '../../../../library/Util'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import {ThemeGraphic} from "../../../../library/theme/Theme"

interface GraphicsListProps {
  graphics: ThemeGraphic[]
  selected?: ThemeGraphic
  handleNewOrder: (graphics: ThemeGraphic[]) => void
  handleSelect: (graphic: ThemeGraphic | undefined) => void
  sx?: SxProps
}

export default function LayerPanel({graphics, selected, handleSelect, handleNewOrder, sx}: GraphicsListProps) {

  const handleTakePosition = (takingPosition: ThemeGraphic, losingPosition: ThemeGraphic) => {
    const takingPositionIndex = graphics.indexOf(takingPosition)
    const losingPositionIndex = graphics.indexOf(losingPosition)

    graphics = update(graphics, {
      $splice: [
        [takingPositionIndex, 1],
        [losingPositionIndex, 0, takingPosition],
      ],
    })

    normalizeZIndexes(graphics, true)
    handleNewOrder([...graphics])
  }

  const handleMouseDown = (e: MouseEvent | null) => {
    if (!e) return

    const element = e.target as Element
    if (element.classList.contains(stylesheet.outline)
      || element.classList.contains(stylesheet.scrollbar)) {
      handleSelect(undefined)
    }
  }

  return (
    <Paper variant="outlined" onMouseDown={handleMouseDown} sx={{p: 1.5, ...sx}}>
      <Stack direction="row" justifyContent="center" alignItems="center">
        <Typography variant="subtitle1">
          Layers
        </Typography>
        <Tooltip title="Drag and drop to change the order.">
          <IconButton>
            <InfoIcon/>
          </IconButton>
        </Tooltip>
      </Stack>

      <ul className={stylesheet.scrollbar}>
        {graphics.map((graphic, index) => {
          return (
            <GraphicThumbnail
              key={graphic.placement.id ?? index * 1000}
              graphic={graphic}
              handleSelect={(graphic) => handleSelect(graphic)}
              isSelected={selected?.placement.id === graphic.placement.id}
              takePosition={(takingPosition, losingPosition) => handleTakePosition(takingPosition, losingPosition)}
            />
          )
        })}
      </ul>
      <Button variant="outlined" startIcon={<AddOutlinedIcon/>}>Add</Button>
    </Paper>
  )
}
