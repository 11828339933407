import stylesheet from "./ThemeListCell.module.scss"
import {Theme} from "../../../library/theme/Theme"

interface ThemeListViewProps {
  theme: Theme
  handleSelectTheme: (theme: Theme) => void
}

export default function ThemeListCell({theme, handleSelectTheme}: ThemeListViewProps) {
  let isNewTheme = (Date.now() / 1000) - theme.createdAt.getTime() < (180 * 24 * 60 * 60)

  return (
    <div key={theme.id}
         className={stylesheet.cell}
         onClick={() => handleSelectTheme(theme.copy())}
    >
      {theme.icon &&
        (
          <img src={theme.icon.toString()} alt="Theme icon"/>
        )
      }
      <div className={stylesheet.summary}>
        <span className={stylesheet.themeName}>{theme.name}</span>
        {isNewTheme && <span className={stylesheet.newCallout}>New</span>}
      </div>
    </div>
  )
}
