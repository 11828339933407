import { ThemeResource } from "./ThemeResource"
import { GraphicPlacement } from "./graphic/GraphicPlacement"
import ImageAsset from "./common/ImageAsset"

export interface ThemeGraphic {
  placement: GraphicPlacement
  assets: ImageAsset[] // Multiple means animate in place
}

export class Theme {
  id: number | null
  userId: number | null
  name: string
  description: string | null
  icon: URL | null
  startScreenGraphics: ThemeGraphic[]
  layoutGraphics: ThemeGraphic[]
  tags: string[]
  createdAt: Date
  updatedAt: Date
  publishedAt: Date | null

  constructor() {
    this.id = null
    this.userId = null
    this.name = ""
    this.description = null
    this.icon = null
    this.startScreenGraphics = []
    this.layoutGraphics = []
    this.tags = []
    
    this.createdAt = new Date()
    this.updatedAt = new Date()
    this.publishedAt = null
  }

  copy(): Theme {
    let copy = new Theme()

    copy.id = this.id
    copy.userId = this.userId
    copy.name = this.name
    copy.description = this.description
    copy.icon = this.icon

    copy.startScreenGraphics = this.startScreenGraphics.map(graphic => {
      return {
        placement: graphic.placement.copy(),
        assets: graphic.assets.map(asset => { return { ...asset }})
      }
    })
    
    copy.layoutGraphics = this.layoutGraphics.map(graphic => {
      return {
        placement: graphic.placement.copy(),
        assets: graphic.assets.map(asset => { return { ...asset }})
      }
    })
    copy.tags = [...this.tags]

    copy.createdAt = new Date(this.createdAt.getTime())
    copy.updatedAt = new Date(this.updatedAt.getTime())

    if (this.publishedAt) {
      copy.publishedAt = new Date(this.publishedAt.getTime())
    }

    return copy
  }

  static fromResource(resource: ThemeResource): Theme {

    let theme = new Theme()

    theme.id = resource.id
    theme.userId = resource.userId
    theme.name = resource.name
    theme.description = resource.description
    theme.icon = resource.icon ? new URL(resource.icon) : null

    theme.startScreenGraphics = resource.startScreenGraphics.map(graphicResource => {
      let graphic: ThemeGraphic = {
        placement: GraphicPlacement.fromResource(graphicResource._embedded.placement),
        assets: graphicResource._embedded.assets
      }
      return graphic
    })

    theme.layoutGraphics = resource.designGraphics.map(graphicResource => {
      let graphic: ThemeGraphic = {
        placement: GraphicPlacement.fromResource(graphicResource._embedded.placement),
        assets: graphicResource._embedded.assets
      }
      return graphic
    })

    theme.tags = resource.tags

    theme.createdAt = new Date(resource.createdAt)
    theme.updatedAt = new Date(resource.updatedAt)

    if (resource.publishedAt) {
      theme.publishedAt = new Date(resource.publishedAt)
    }

    return theme
  }
}
