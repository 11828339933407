import React, {ChangeEvent, CSSProperties, useLayoutEffect, useState} from 'react'
import CompositionView from '../../CompositionView/CompositionView'
import stylesheet from "./PreviewArea.module.scss"
import {Box, Checkbox, FormControlLabel, Stack, SxProps, Typography} from '@mui/material'
import AspectRatio from '../../../library/AspectRatio'
import {ThemeGraphic} from "../../../library/theme/Theme"

interface PreviewAreaProps {
  graphics: ThemeGraphic[]
  aspectRatios: AspectRatio[]
  showSampleImage?: boolean
  sx?: SxProps
}

export default function PreviewArea({graphics, showSampleImage, aspectRatios, sx}: PreviewAreaProps) {
  const [previewAreaHeight, setPreviewAreaHeight] = useState<number>(0)
  const [enabledRatios, setEnabledRatios] = useState<AspectRatio[]>(aspectRatios.filter((ratio) => ratio.name === "Portrait" || ratio.name === "Landscape"))

  const previewAreaRef = React.createRef<HTMLDivElement>()

  const MAX_PREVIEW_HEIGHT = 640

  useLayoutEffect(() => {
    updatePreviewSize()
  }, [previewAreaRef, enabledRatios])

  useLayoutEffect(() => {
    window.addEventListener('resize', updatePreviewSize)
    return () => window.removeEventListener('resize', updatePreviewSize)
  }, [previewAreaRef])

  const updatePreviewSize = () => {
    if (!previewAreaRef.current) return

    const div = previewAreaRef.current
    const totalPreviewAspectRatio: number = enabledRatios.reduce((partialSum, b) => partialSum + b.value, 0)

    let spacing = 0
    for (let preview of Array.from(div.children)) {
      spacing += parseFloat(getComputedStyle(preview).marginLeft)
    }

    const previewAreaWidth = div.clientWidth - spacing
    const height = Math.round(previewAreaWidth / totalPreviewAspectRatio)
    const finalHeight = Math.min(height, MAX_PREVIEW_HEIGHT)
    setPreviewAreaHeight(finalHeight)
  }

  const gfxViewStyle: CSSProperties = {
    backgroundColor: "none",
    border: "1px solid RGBA(0, 0, 0, 0.12)",
  }

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>, ratio: AspectRatio) => {
    let newRatios = [...enabledRatios]
    if (event.target.checked) {
      newRatios.push(ratio)
      newRatios.sort((r1, r2) => r1.value - r2.value)
      setEnabledRatios(newRatios)
    } else {
      const index = newRatios.indexOf(ratio)
      newRatios.splice(index, 1)
      setEnabledRatios(newRatios)
    }
  }

  return (
    <Box sx={sx}>
      <Stack direction="row" alignItems="center">
        <Typography variant="subtitle2" sx={{mr: 2}}>Previews: </Typography>
        {aspectRatios.map((ratio) => {
          const checked = enabledRatios.includes(ratio)
          const checkbox = (
            <Checkbox
              checked={checked}
              disabled={checked && enabledRatios.length <= 1}
              onChange={(event) => handleCheckboxChange(event, ratio)}
            />
          )
          return (
            <FormControlLabel
              key={ratio.name}
              control={checkbox}
              label={ratio.name}
            />
          )
        })}
      </Stack>

      <Stack direction="row"
             spacing={1}
             ref={previewAreaRef}
             height={previewAreaHeight}
             key={enabledRatios.map(ratio => `${ratio}`).join()}
      >
        {enabledRatios.map((ratio, index) => {
          return (
            <CompositionView
              key={ratio.name}
              aspectRatio={ratio.value}
              graphics={graphics}
              showSampleImage={showSampleImage}
              className={stylesheet.previewAppear}
              style={{
                flexBasis: Math.floor(previewAreaHeight * ratio.value) + "px",
                animationDelay: (index * 100) + "ms",
                ...gfxViewStyle,
              }}
            />
          )
        })}
      </Stack>
    </Box>
  )
}
