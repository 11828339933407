export type SuccessCallback = (json: any) => void

function parseJsonResponse(response: Response) {
  if (!response.ok) {
    throw new Error(`${response.status}`)
  }
  if (response.redirected) {
    throw new Error("302")
  }
  if (response.status === 204) {
    return {}
  }
  return response.json()
}

function doFetch(url: RequestInfo, options: RequestInit, handleResponse?: (response: any) => void, handleError?: (code: number) => void) {
  fetch(url, options)
    .then(parseJsonResponse)
    .then(json => {
      if (handleResponse) handleResponse(json)
    }).catch(error => {
      if (handleError)
        handleError(
          parseInt(error.message) // 404, 500, etc
        )
    })
}

export function fetchGet(url: string, handleSuccess?: SuccessCallback, handleError?: (code: number) => void) {
  doFetch(url, { method: "get" }, handleSuccess, handleError)
}

export function fetchPost(url: string, body: FormData | Object | null, handleSuccess?: SuccessCallback, handleError?: (code: number) => void) {
  let options: RequestInit = { method: "post" }
  if (body) {
    if (body instanceof FormData) {
      options.body = body
    } else if (typeof body === 'object') {
      options.headers = { 'Content-Type': 'application/json'}
      options.body = JSON.stringify(body)
    } else {
      options.body = body
    }
  }
  doFetch(url, options, handleSuccess, handleError)
}

export function fetchDelete(url: string, handleSuccess?: SuccessCallback, handleError?: (code: number) => void) {
  doFetch(url, { method: "delete" }, handleSuccess, handleError)
}
