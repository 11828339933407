import {GraphicPlacementResource} from "./GraphicPlacementResource"

export class GraphicPlacement {
  id: number | null
  enabled: boolean
  anchor: {
    x: number
    y: number
  }
  position: {
    x: number
    y: number
  }
  maxSize: {
    width: number
    height: number
  }
  rotation: number
  zIndex: number
  supportedAspectRatio: {
    min: number
    max: number
  }
  supportedLayoutPhotos: {
    min: number
    max: number
  }

  constructor() {
    this.id = null
    this.enabled = true
    this.anchor = {x: 0, y: 0}
    this.position = {x: 0, y: 0}
    this.maxSize = {width: 1, height: 1}
    this.rotation = 0
    this.zIndex = 0
    this.supportedAspectRatio = {min: 0, max: 10}
    this.supportedLayoutPhotos = {min: 0, max: 10}
  }

  copy(): GraphicPlacement {
    let copy = new GraphicPlacement()

    copy.enabled = this.enabled
    copy.id = this.id
    copy.anchor = {...this.anchor}
    copy.position = {...this.position}
    copy.maxSize = {...this.maxSize}
    copy.rotation = this.rotation
    copy.zIndex = this.zIndex
    copy.supportedAspectRatio = {...this.supportedAspectRatio}
    copy.supportedLayoutPhotos = {...this.supportedLayoutPhotos}

    return copy
  }

  static fromResource(resource: GraphicPlacementResource): GraphicPlacement {
    let placement = new GraphicPlacement()

    placement.enabled = true
    placement.id = resource.id
    placement.anchor = {
      x: resource.anchorX,
      y: resource.anchorY,
    }
    placement.position = {
      x: resource.positionX,
      y: resource.positionY,
    }
    placement.maxSize = {
      width: resource.maxSizeWidth,
      height: resource.maxSizeHeight,
    }
    placement.rotation = resource.rotation
    placement.zIndex = resource.zIndex
    placement.supportedAspectRatio = {
      min: resource.aspectMin,
      max: resource.aspectMax,
    }
    placement.supportedLayoutPhotos = {
      min: resource.minLayoutPhotos,
      max: resource.maxLayoutPhotos,
    }

    return placement
  }
}
