/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import ThemeListView from '../ThemeListView/ThemeListView'
import ThemeDetailView from '../ThemeDetailView/ThemeDetailView'
import ThemeEditor from '../Editor/ThemeEditor/ThemeEditor'
import {useLocation, useNavigate} from 'react-router-dom'
import {Box, Stack} from "@mui/material"
import {Theme} from "../../library/theme/Theme"

export default function ExplorerController() {
  let navigate = useNavigate()
  let location = useLocation()

  let pathComponents = location.pathname.split("/")
  let lastComponent = pathComponents[pathComponents.length - 1]

  const [theme, setTheme] = useState<Theme | null>(null)
  const [isEditing, setIsEditing] = useState<boolean>(lastComponent === "edit")

  useEffect(() => {
    if (!theme) return
    navigate(`/themes/${theme.id}${isEditing ? '/edit' : ''}`);
  }, [theme, isEditing])

  if (isEditing && theme) {
    return <ThemeEditor key={theme.id} theme={theme} handleClose={() => setIsEditing(false)} />
  }

  return (
    <Stack direction="row" spacing={2} sx={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, p:3}}>
      <ThemeListView handleSelectTheme={setTheme} />
      <Box sx={{flex: 1}}>
        {theme && (
          <ThemeDetailView key={theme.id} theme={theme} handleEditClick={() => setIsEditing(true)} />
        )}
      </Box>
    </Stack>
  )
}
