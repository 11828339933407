/* eslint-disable jsx-a11y/alt-text */
import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import CompositionView from '../CompositionView/CompositionView'
import styles from "./Demo.module.scss"
import HaloBodySrc from "./halo-1-body.png"
import HaloScreenReflectionSrc from "./halo-1-reflection.png"
import iPhone14BezelSrc from "./iphone-14-portrait.png"
import SatusSymbolsSrc from "./iphone-status-symbols.png"
import {fetchPublicThemes} from "../../library/theme/ThemeRequests"
import {Theme} from "../../library/theme/Theme"
import Spinner from "../Spinner/Spinner"

export default function Demo() {
  const [themes, setThemes] = useState<Theme[] | null>(null)
  const [theme, setTheme] = useState<Theme | null>(null)
  const [errorCode, setErrorCode] = useState<number | null>(null)

  let {themeId} = useParams()

  // TODO: Get just the one theme, this is very inefficient
  useEffect(() => {
    fetchPublicThemes((themes) => {
      if (!themeId) {
        return
      }

      setThemes(themes)

      const foundTheme = themes.filter(theme => theme.id === parseInt(themeId as string))
      if (foundTheme.length === 1) {
        setTheme(foundTheme[0])
      }
    }, setErrorCode)
  }, [themeId])

  if (errorCode) {
    return <>{errorCode}</>
  }

  if (!themes || !theme) {
    return <Spinner centerVertical/>
  }

  let timeStr = new Date().toLocaleString('en-US', {hour: '2-digit', minute: '2-digit'})
  timeStr = timeStr.replace(' AM', '').replace(' PM', '')

  return (
    <div className={styles.background}>
      {/*<div className={styles.themeList}>*/}
      {/*  {themes.map(item => {*/}
      {/*    return <button*/}
      {/*      key={item.id}*/}
      {/*      className={item.id === theme.id ? styles.selected : ''}*/}
      {/*      onClick={(event) => setTheme(item)}*/}
      {/*    >*/}
      {/*      {item.name}*/}
      {/*    </button>*/}
      {/*  })}*/}
      {/*</div>*/}
      <div className={styles.halo}>
        <div className={styles.square}>
          <img className={styles.reflection} src={HaloScreenReflectionSrc}/>
          <img className={styles.body} src={HaloBodySrc}/>

          <div key={`${theme.name}_haloScreen`} className={`${styles.screen} ${styles.fadeIn}`}>
            <CompositionView
              aspectRatio={0.75}
              graphics={theme.startScreenGraphics}
              style={{height: '100%'}}
            />
          </div>
        </div>
      </div>
      <div className={styles.phone}>
        <img className={styles.bezel} src={iPhone14BezelSrc}/>
        <div className={styles.screen}>
          <div className={styles.topBar}>
            <div className={styles.time}>
              {timeStr}
            </div>
            <div className={styles.statusSymbols}>
              <img src={SatusSymbolsSrc}/>
            </div>
          </div>
          <div key={`${theme.name}_iPhoneScreen`} className={`${styles.inside} ${styles.fadeIn}`}>
            <span>
              Photo
            </span>
            <CompositionView
              aspectRatio={0.75}
              graphics={theme.layoutGraphics}
              showSampleImage
              style={{width: "100%", paddingBottom: `${100 / 0.75}%`}}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
