import {Slider, Tooltip} from "@mui/material"
import stylesheet from "./PlacementEditor.module.scss"
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore'
import {Box} from "@mui/system"
import useEventListener from "@use-it/event-listener"

interface SnapControlProps {
  position: Point
  anchor: Point
  handleChange: (position: Point, anchor: Point) => void
}

interface Point {
  x: number,
  y: number
}

interface SnapPoints {
  position: Point,
  anchor: Point
}

enum VerticalSnap {
  top, center, bottom
}

enum HorizontalSnap {
  left, center, right
}

interface SnapSpot {
  vertical: VerticalSnap,
  horizontal: HorizontalSnap,
  symbol: string
}

export default function SnapControl({position, anchor, handleChange}: SnapControlProps) {

  const handler = (event: KeyboardEvent) => {
    const {key, shiftKey} = event

    if ((event.target as HTMLElement)?.tagName === "INPUT") return

    const supportedKeys = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight']
    if (!supportedKeys.includes(key)) return
    event.preventDefault()

    const amount = shiftKey ? 0.03 : 0.0025

    if (key === 'ArrowUp') {
      position.y -= amount
    } else if (key === 'ArrowDown') {
      position.y += amount
    } else if (key === 'ArrowLeft') {
      position.x -= amount
    } else if (key === 'ArrowRight') {
      position.x += amount
    }
    handleChange(position, anchor)
  }

  useEventListener('keydown', handler)

  const spots: SnapSpot[] = [
    {
      vertical: VerticalSnap.top,
      horizontal: HorizontalSnap.left,
      symbol: "↖",
    },
    {
      vertical: VerticalSnap.top,
      horizontal: HorizontalSnap.center,
      symbol: "↑",
    },
    {
      vertical: VerticalSnap.top,
      horizontal: HorizontalSnap.right,
      symbol: "↗",
    },
    {
      vertical: VerticalSnap.center,
      horizontal: HorizontalSnap.left,
      symbol: "←",
    },
    {
      vertical: VerticalSnap.center,
      horizontal: HorizontalSnap.center,
      symbol: "•",
    },
    {
      vertical: VerticalSnap.center,
      horizontal: HorizontalSnap.right,
      symbol: "→",
    },
    {
      vertical: VerticalSnap.bottom,
      horizontal: HorizontalSnap.left,
      symbol: "↙",
    },
    {
      vertical: VerticalSnap.bottom,
      horizontal: HorizontalSnap.center,
      symbol: "↓",
    },
    {
      vertical: VerticalSnap.bottom,
      horizontal: HorizontalSnap.right,
      symbol: "↘",
    },
  ]

  let getSnapPoints = (vertical: VerticalSnap, horizontal: HorizontalSnap): SnapPoints => {
    return {
      position: {
        x: horizontal / 2,
        y: vertical / 2,
      },
      anchor: {
        x: horizontal / 2,
        y: vertical / 2,
      },
    }
  }

  let isSnapped = (vertical: VerticalSnap, horizontal: HorizontalSnap) => {
    const snapPoints = getSnapPoints(vertical, horizontal)
    return anchor.x === snapPoints.anchor.x
      && anchor.y === snapPoints.anchor.y
  }

  let snap = (vertical: VerticalSnap, horizontal: HorizontalSnap) => {
    const {position, anchor} = getSnapPoints(vertical, horizontal)
    handleChange(position, anchor)
  }

  const currentSnapSpot = spots.filter((spot) => {
    return isSnapped(spot.vertical, spot.horizontal)
  })[0]
  const currentBaseSnapPoints = getSnapPoints(currentSnapSpot.vertical, currentSnapSpot.horizontal)
  const offsetValues = {
    x: position.x - currentBaseSnapPoints.position.x,
    y: position.y - currentBaseSnapPoints.position.y,
  }

  const adjustXPosition = (event: Event, newValue: number | number[]) => {
    const newPosition = {...position}
    newPosition.x = currentBaseSnapPoints.position.x + ((newValue as number) / 100)
    handleChange(newPosition, anchor)
  }

  const adjustYPosition = (event: Event, newValue: number | number[]) => {
    const newPosition = {...position}
    newPosition.y = currentBaseSnapPoints.position.y + ((newValue as number) / 100)
    handleChange(newPosition, anchor)
  }

  const resetPosition = () => {
    const newPosition = {...position}
    newPosition.x = currentBaseSnapPoints.position.x
    newPosition.y = currentBaseSnapPoints.position.y
    handleChange(newPosition, anchor)
  }

  return (
    <Box sx={{m: 0.5}}>
      <div className={stylesheet.positionCol}>
        <div className={stylesheet.positionRow}>
          <div className={stylesheet.buttonGrid}>
            {spots.map((spot) => {
              return (
                <button
                  key={`${spot.vertical}-${spot.horizontal}`}
                  onClick={() => snap(spot.vertical, spot.horizontal)}
                  className={isSnapped(spot.vertical, spot.horizontal) ? stylesheet.selected : ''}
                >
                  {spot.symbol}
                </button>
              )
            })}
          </div>
          <div className={stylesheet.verticalSliderContainer}>
            <Slider
              sx={{
                '& input[type="range"]': {
                  WebkitAppearance: 'slider-vertical',
                },
              }}
              getAriaLabel={() => 'Adjust Y Position'}
              min={-100}
              max={100}
              step={1}
              value={Math.round(offsetValues.y * -100)}
              onChange={(event, newValue) => adjustYPosition(event, -newValue)}
              valueLabelDisplay="auto"
              valueLabelFormat={(value) => `${-value}%`}
              getAriaValueText={(value) => `${-value}%`}
              orientation="vertical"
              track="inverted"
            />
          </div>
        </div>
        <div className={stylesheet.horizontalSliderContainer}>
          <Slider
            getAriaLabel={() => 'Adjust X Position'}
            min={-100}
            max={100}
            step={1}
            value={Math.round(offsetValues.x * 100)}
            onChange={adjustXPosition}
            valueLabelDisplay="auto"
            valueLabelFormat={(value) => `${value}%`}
            getAriaValueText={(value) => `${value}%`}
          />

          <Tooltip title="Reset X and Y offsets">
            <button className={stylesheet.resetButton} onClick={resetPosition}>
              <SettingsBackupRestoreIcon/>
            </button>
          </Tooltip>
        </div>
      </div>
    </Box>
  )
}
