import {Theme} from "./theme/Theme"

interface CalendarDay {
  month: number
  day: number
}

export default class ThemeCategory {
  title: string
  filterTag: string
  themes: Theme[]
  featureStart?: CalendarDay
  featureEnd?: CalendarDay
  order: number

  constructor(order: number, title: string, filterTag: string, featureStart?: CalendarDay, featureEnd?: CalendarDay, themes?: Theme[]) {
    this.title = title
    this.filterTag = filterTag
    this.themes = themes ?? []
    this.featureStart = featureStart
    this.featureEnd = featureEnd
    this.order = order
  }

  populateThemes(themes: Theme[]) {
    this.themes = themes.filter(theme => {
      return theme.tags.includes(this.filterTag)
    })
  }

  static getFeaturedCategories(themes: Theme[]): ThemeCategory[] {
    let categories = [
      new ThemeCategory(3, "Weddings", "wedding"),
      new ThemeCategory(4, "Birthdays", "birthday"),
      new ThemeCategory(35, "Valentine's Day", "valentines-day", {month: 2, day: 1}, {month: 2, day: 14}),
      new ThemeCategory(40, "St. Patrick's Day", "st-patricks-day", {month: 3, day: 1}, {month: 3, day: 17}),
      new ThemeCategory(45, "Easter", "easter", {month: 4, day: 1}, {month: 4, day: 30}),
      new ThemeCategory(50, "Cinco De Mayo", "cinco-de-mayo", {month: 4, day: 15}, {month: 5, day: 5}),
      new ThemeCategory(55, "Mother's Day", "mothers-day", {month: 5, day: 1}, {month: 5, day: 14}),
      new ThemeCategory(60, "Graduation", "graduation", {month: 5, day: 1}, {month: 6, day: 10}),
      new ThemeCategory(65, "Summer Season", "summer-season", {month: 5, day: 15}, {month: 8, day: 30}),
      new ThemeCategory(70, "Independence Day", "independence-day", {month: 6, day: 1}, {month: 7, day: 4}),
      new ThemeCategory(75, "Father's Day", "fathers-day", {month: 6, day: 1}, {month: 6, day: 18}),
      new ThemeCategory(80, "Fall Season", "fall-season", {month: 9, day: 15}, {month: 11, day: 30}),
      new ThemeCategory(85, "Halloween", "halloween", {month: 9, day: 15}, {month: 10, day: 31}),
      new ThemeCategory(90, "Thanksgiving", "thanksgiving", {month: 11, day: 1}, {month: 11, day: 28}),
      new ThemeCategory(95, "Winter Season", "winter-season", {month: 11, day: 1}, {month: 1, day: 31}),
      new ThemeCategory(100, "Hanukkah", "hanukkah", {month: 12, day: 1}, {month: 12, day: 26}),
      new ThemeCategory(105, "Christmas", "christmas", {month: 11, day: 1}, {month: 12, day: 26}),
      new ThemeCategory(110, "New Years", "new-years", {month: 12, day: 1}, {month: 12, day: 31}),
      new ThemeCategory(125, "80s Party", "80s"),
    ]

    categories.forEach(category => {
      category.populateThemes(themes)

      let currentMonth = new Date().getMonth() + 1

      if (ThemeCategory.shouldFeature(category)) {
        category.order = 0
      } else if (category.featureStart && category.featureStart.month >= currentMonth) {
        category.order = category.featureStart.month - currentMonth
      }
    })

    categories.sort((a, b) => a.order - b.order)

    const categorizedThemes = categories.flatMap(category => category.themes)
    const uncategorizedThemes = themes.filter(theme => categorizedThemes.find(categorized => categorized.id === theme.id) === undefined)
    const uncategorizedCategory = new ThemeCategory(1000, "Uncategorized", "", undefined, undefined, uncategorizedThemes)
    categories.push(uncategorizedCategory)

    return categories.filter(category => category.themes.length > 0)
  }

  static shouldFeature(category: ThemeCategory): boolean {
    let start = category.featureStart
    let end = category.featureEnd

    if (!start || !end) {
      return false
    }

    let currentTime = new Date()
    let currentMonth = currentTime.getMonth() + 1 // JS dates start at 0, ours start at 1
    let currentDay = currentTime.getDate()

    if (start.month > currentMonth) {
      return false
    }

    if (start.month === currentMonth && start.day > currentDay) {
      return false
    }

    if (end.month < currentMonth) {
      return false
    }

    if (end.month === currentMonth && end.day < currentDay) {
      return false
    }

    return true
  }
}
