import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import stylesheet from "./CompositionView.module.scss"
import {ThemeGraphic} from "../../library/theme/Theme"

interface CompositionViewProps {
  graphics: ThemeGraphic[]
  aspectRatio: number
  showAll?: boolean
  showSampleImage?: boolean
  className?: string
  style?: CSSProperties
}

export default function CompositionView({ graphics, aspectRatio, showAll, showSampleImage, className, style }: CompositionViewProps) {
  const [tick, setTick] = useState<number>(1)
  const tickRef = useRef(tick)
  tickRef.current = tick

  useEffect(() => {
    const interval = setInterval(() => {
      setTick(tickRef.current + 1)
    }, 200);
    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setTick])

  const containerStyle: CSSProperties = { 
    position: "relative",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    ...style
  }

  if (showSampleImage) {
    let imageFilename = "sample-portrait.jpg"
    if (aspectRatio <= 0.5) {
      imageFilename = "sample-square.jpg"
      containerStyle.backgroundSize = "100%"
      containerStyle.backgroundPositionY = "top"
    } else if (aspectRatio === 1) {
      imageFilename = "sample-square.jpg"
    } else if (aspectRatio > 1) {
      imageFilename = "sample-landscape.jpg"
    }
    containerStyle.backgroundImage = `url(${process.env.PUBLIC_URL}/${imageFilename})`
  }

  return (
    <div
      className={`${stylesheet.container} ${className}`}
      style={containerStyle}>
      {graphics.map((graphic, index) => {

        if (!showAll && (!graphic.placement.enabled
          || graphic.placement.supportedAspectRatio.min > aspectRatio
          || graphic.placement.supportedAspectRatio.max < aspectRatio)) {
          return <></>
        }

        let placement = graphic.placement
        let anchorTranslate = `translate(-${(placement.anchor.x) * 100}%, -${(placement.anchor.y) * 100}%)`
        let rotateTransform = `rotate(${placement.rotation}deg)`

        let divStyle: CSSProperties = {
          position: 'absolute',
          left: `${placement.position.x * 100}%`,
          top: `${placement.position.y * 100}%`,
          width: `${placement.maxSize.width * 100}%`,
          height: `${placement.maxSize.height * 100}%`,
          zIndex: placement.zIndex
        }

        let imgStyle: CSSProperties = {
          transform: `${anchorTranslate} ${rotateTransform}`,
          maxHeight: '100%',
          maxWidth: '100%'
        }

        return (
          <div key={index} style={divStyle}>
            <img src={graphic.assets[tick % graphic.assets.length].url} alt="" style={imgStyle} />
          </div>
        )
      })}
    </div>
  )
}
