import { Theme } from "./Theme"
import { ThemeResource } from "./ThemeResource"
import {fetchGet} from "./common/Requests"
import API from "./common/API"

type FetchPublicThemesCallback = (themes: Theme[]) => void

export function fetchPublicThemes(handleSuccess: FetchPublicThemesCallback, handleError: (code: number) => void) {
  fetchGet(
    `${API.baseUrl}/api/themes/public`,
    (json) => {
      let themes = json.map((themeResource: ThemeResource) => Theme.fromResource(themeResource))
      handleSuccess(themes)
    },
    handleError
  )
}
